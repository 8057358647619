import { Feature } from "@/interfaces/places";

export interface PlacesState {
  loading: boolean;
  loadingPlaces: boolean;
  userLocation?: [number, number];
  places: Feature[];
}

function state(): PlacesState {
  return {
    loading: true,
    loadingPlaces: false,
    userLocation: undefined,
    places: [],
  };
}

export default state;
