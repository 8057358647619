import { MutationTree } from "vuex";
import { PlacesState } from "./state";
import { Feature } from "@/interfaces/places";

const mutation: MutationTree<PlacesState> = {
  setLoading(state, payload: boolean) {
    state.loading = payload;
  },
  setLoadingPlaces(state, payload: boolean) {
    state.loadingPlaces = payload;
  },
  setUserLocation(state, { lng, lat }: { lat: number; lng: number }) {
    state.userLocation = [lng, lat];
  },
  setPlaces(state, payload: Feature[]) {
    state.places = payload;
  },
};

export default mutation;
