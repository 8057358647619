import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchBar = _resolveComponent("SearchBar")!
  const _component_MapView = _resolveComponent("MapView")!
  const _component_MyLocationButton = _resolveComponent("MyLocationButton")!
  const _component_TripDetails = _resolveComponent("TripDetails")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SearchBar),
    _createVNode(_component_MapView),
    _createVNode(_component_MyLocationButton),
    _createVNode(_component_TripDetails)
  ]))
}