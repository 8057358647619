import { createStore } from "vuex";
import { PlacesState } from "./places/state";
import places from "./places";
import map from "./map";
import { MapState } from "./map/state";

export interface StateInterface {
  places: PlacesState;
  map: MapState;
}

export default createStore<StateInterface>({
  modules: {
    places,
    map,
  },
});
