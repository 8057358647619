export interface PlacesResponse {
  type: string;
  query: string[];
  features: Feature[];
  attribution: string;
}

export interface Feature {
  id: string;
  type: string;
  place_type: string[];
  relevance: number;
  properties: Properties;
  text_en: string;
  language_en?: Language;
  place_name_en: string;
  text: string;
  language?: Language;
  place_name: string;
  matching_text?: string;
  matching_place_name?: string;
  center: number[];
  geometry: Geometry;
  context: Context[];
}

export interface Context {
  id: string;
  mapbox_id: string;
  wikidata?: Wikidata;
  text_en: string;
  language_en?: Language;
  text: string;
  language?: Language;
  short_code?: ShortCode;
}

export enum Language {
  En = "en",
}

export enum ShortCode {
  Hn = "hn",
  HnCR = "HN-CR",
}

export enum Wikidata {
  Q274584 = "Q274584",
  Q767244 = "Q767244",
  Q783 = "Q783",
}

export interface Geometry {
  coordinates: number[];
  type: string;
}

export interface Properties {
  foursquare: string;
  landmark: boolean;
  address?: string;
  category: string;
}
