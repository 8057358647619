import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

if (!navigator.geolocation) {
  alert("Geolocation is not supported by your browser");
  throw new Error("Geolocation is not supported by your browser");
}

import mapboxgl from "mapbox-gl";
mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN as string;

createApp(App).use(store).use(router).mount("#app");
