import { GetterTree } from "vuex";
import { MapState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<MapState, StateInterface> = {
  map(state) {
    return state.map;
  },
  distance(state) {
    return state.distance;
  },
  duration(state) {
    return state.duration;
  },
  isMapReady(state) {
    return !!state.map;
  },
};

export default getters;
