import { GetterTree } from "vuex";
import { PlacesState } from "./state";
import { StateInterface } from "../index";

const getters: GetterTree<PlacesState, StateInterface> = {
  isUserLocationReady(state) {
    return !!state.userLocation;
  },

  getUserLocation(state) {
    return state.userLocation;
  },

  getLoading(state) {
    return state.loading;
  },

  getLoadingPlaces(state) {
    return state.loadingPlaces;
  },

  getPlaces(state) {
    return state.places;
  },
};

export default getters;
