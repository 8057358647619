<template>
  <div class="home">
    <SearchBar />
    <MapView />
    <MyLocationButton />
    <TripDetails />
  </div>
</template>

<script lang="ts">
import { usePlacesStore } from "@/composables";
import { defineAsyncComponent, defineComponent } from "vue";

export default defineComponent({
  name: "HomeView",
  components: {
    MapView: defineAsyncComponent(
      () => import("@/components/map-view/MapView.vue")
    ),
    MyLocationButton: defineAsyncComponent(
      () => import("@/components/my-location-button/MyLocationButton.vue")
    ),
    SearchBar: defineAsyncComponent(
      () => import("@/components/search-bar/SearchBar.vue")
    ),
    TripDetails: defineAsyncComponent(
      () => import("@/components/trip-details/TripDetails.vue")
    ),
  },
  setup() {
    // eslint-disable-next-line no-empty-pattern
    const {} = usePlacesStore();
    return {};
  },
});
</script>
