import { Language } from "@/interfaces/places";
import axios from "axios";

const searchApi = axios.create({
  baseURL: "https://api.mapbox.com/geocoding/v5/mapbox.places",
  params: {
    access_token: process.env.VUE_APP_MAPBOX_ACCESS_TOKEN,
    language: Language.En,
  },
});

export default searchApi;
