import { ActionTree } from "vuex";
import { PlacesState } from "./state";
import { StateInterface } from "../index";
import { searchApi } from "@/apis";
import { Feature, PlacesResponse } from "@/interfaces/places";

const actions: ActionTree<PlacesState, StateInterface> = {
  getInitialLocation({ commit }) {
    commit("setLoading", true);
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        commit("setUserLocation", {
          lng: coords.longitude,
          lat: coords.latitude,
        });
        commit("setLoading", false);
      },
      (err) => {
        commit("setLoading", false);
        // eslint-disable-next-line no-console
        console.error(err);
        throw new Error("Failed to get user location 🥺");
      }
    );
  },

  async searchPlacesByTerm(
    { commit, getters },
    term: string
  ): Promise<Feature[]> {
    if (term.length === 0) {
      return [];
    }
    const currentLocation = getters["getUserLocation"] as [number, number];

    if (!currentLocation) {
      throw new Error("User location is not available");
    }

    const proximity = currentLocation.join(",");

    try {
      commit("setLoadingPlaces", true);
      const { data } = await searchApi.get<PlacesResponse>(`/${term}.json`, {
        params: {
          proximity,
        },
      });
      commit("setPlaces", data.features);
      return data.features;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw new Error("Failed to search places 😢");
    } finally {
      commit("setLoadingPlaces", false);
    }
  },
};

export default actions;
