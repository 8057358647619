import { useStore } from "vuex";
import { StateInterface } from "@/store";
import { computed, onMounted } from "vue";
import { Feature } from "@/interfaces/places";

export const usePlacesStore = () => {
  const store = useStore<StateInterface>();

  const getInitialLocation = () => store.dispatch("places/getInitialLocation");

  onMounted(() => {
    const initialLocation = store.getters["places/getInitialLocation"];
    if (!initialLocation) {
      getInitialLocation();
    }
  });

  return {
    loading: computed<boolean>(() => store.getters["places/getLoading"]),
    userLocation: computed<[number, number]>(
      () => store.getters["places/getUserLocation"]
    ),
    isUserLocationReady: computed<boolean>(
      () => store.getters["places/isUserLocationReady"]
    ),
    places: computed<Feature[]>(() => store.getters["places/getPlaces"]),
    loadingPlaces: computed<boolean>(
      () => store.getters["places/getLoadingPlaces"]
    ),
    searchPlacesByTerm: (term: string) =>
      store.dispatch("places/searchPlacesByTerm", term),
    setMarkers: (places: Feature[]) =>
      store.commit("map/setPlaceMarkers", places),
  };
};
