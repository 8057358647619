import { MutationTree } from "vuex";
import { MapState } from "./state";
import Mapboxgl from "mapbox-gl";
import { Feature } from "@/interfaces/places";

const mutation: MutationTree<MapState> = {
  setMap(state: MapState, payload: Mapboxgl.Map) {
    state.map = payload;
  },

  setPlaceMarkers(state: MapState, payload: Feature[]) {
    if (!state.map) {
      return;
    }

    const markers = state.markers;
    if (markers) {
      markers.forEach((marker) => {
        marker.remove();
      });
    }

    state.markers = [];
    const newMarkers = payload.map((place) => {
      const [lng, lat] = place.center;
      const marker = new Mapboxgl.Marker()
        .setLngLat([lng, lat])
        .setPopup(
          new Mapboxgl.Popup({ offset: 25 }).setHTML(
            `<h3>${place.place_name}</h3>`
          )
        )
        .addTo(state.map!);
      return marker;
    });
    state.markers = newMarkers;

    if (state.map?.getLayer("RouteString")) {
      state.map.removeLayer("RouteString");
      state.map.removeSource("RouteString");
      state.distance = undefined;
      state.duration = undefined;
    }
  },

  setRoutePolyline(state, coords: number[][]) {
    const start = coords[0];
    const end = coords[coords.length - 1];

    // Definir los bounds
    const bounds = new Mapboxgl.LngLatBounds(
      [start[0], start[1]],
      [start[0], start[1]]
    );

    // Agregamos cada punto al bounds
    for (const coord of coords) {
      const newCoord: [number, number] = [coord[0], coord[1]];
      bounds.extend(newCoord);
    }

    state.map?.fitBounds(bounds, {
      padding: 100,
    });

    // Polyline
    const sourceData: Mapboxgl.AnySourceData = {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: coords,
            },
          },
        ],
      },
    };

    if (state.map?.getLayer("RouteString")) {
      state.map.removeLayer("RouteString");
      state.map.removeSource("RouteString");
    }

    if (state.map?.getLayer("RouteString")) {
      state.map.removeLayer("RouteString");
      state.map.removeSource("RouteString");
    }

    state.map?.addSource("RouteString", sourceData);

    state.map?.addLayer({
      id: "RouteString",
      type: "line",
      source: "RouteString",
      layout: {
        "line-cap": "round",
        "line-join": "round",
      },
      paint: {
        "line-color": "black",
        "line-width": 3,
      },
    });
  },

  setDistanceDuration(
    state,
    { distance, duration }: { distance: number; duration: number }
  ) {
    let kms = distance / 1000;
    kms = Math.round(kms * 100);
    kms /= 100;
    state.distance = kms;
    state.duration = Math.floor(duration / 60);
  },
};

export default mutation;
